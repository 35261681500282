import React from 'react';
import { PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';

import LayoutLanding from '../components/LayoutLanding';
import SEO from '../components/seo/SEO';
import Address from '../components/Address';
import ContactForm from '../components/ContactForm';
import useI18n from '../utils/useI18n';
import LandingIMG from '../components/images/LandingIMG';

const Landing = ({ pageContext: { switchLang }, location }) => {
  const { t } = useI18n();
  return (
    <LayoutLanding switchLang={switchLang}>
      <SEO
        title={t('landing.meta.title')}
        description={t('landing.meta.description')}
        keywords={t('contact.meta.keywords')}
        pathname={location.pathname}
        altTitle={t('menu.contact')}
      />
      <div className="o-wrapper u-align-center">
        <div className="u-margin-top u-10/12@desktop u-push-1/12@desktop">
          <div className="c-hero u-padding-top-small u-padding-bottom">
            <div className="c-hero__title">
              <h1 className="o-hugeTitle">{t('landing.title')}</h1>
            </div>
            <div className="c-hero__callout">
              <p className="o-callout">{t('landing.callout')}</p>
            </div>
          </div>
        </div>
      </div>
      <section className="c-section c-section--white">
        <div className="c-section__content o-wrapper">
          <div className="o-layout">
            <div className="u-6/12 u-align-left o-layout__item">
              <h2 className="o-largeTitle">{t('landing.subtitle')}</h2>
              <ul>
                <li>{t('landing.item1')}</li>
                <li>{t('landing.item2')}</li>
                <li>{t('landing.item3')}</li>
                <li>{t('landing.item4')}</li>
                <li>{t('landing.item5')}</li>
              </ul>
              <strong className="u-margin-left u-h4">{t('landing.secondaryText')}</strong>
            </div>
            <div className="u-6/12  o-layout__item">
              <LandingIMG />
            </div>
          </div>
        </div>
      </section>
      <div className="o-wrapper u-align-center u-margin-top-large">
        <div className="u-margin-top u-10/12@desktop u-push-1/12@desktop">
          <h1 className="o-largeTitle">{t('landing.contact.title')}</h1>
          <p className="o-callout">{t('contact.text')}</p>
          <span className="u-h2">{t('phone')}</span>
          <span>{t('time')}</span>
          <div className="u-margin-top">
            <a href="tel:8882753595" className="c-button c-button--large u-margin-right">
              <PhoneOutlined /> {t('contact.callUs')}
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5213325107552&text=Hi,%20I%20need%20help%20for%20cancel%20my%20timeshare%20contract"
              className="c-button c-button--large c-button--secondary"
            >
              {t('contact.whatsApp')} <WhatsAppOutlined />
            </a>
            <span className="u-h4 u-margin-top-large">{t('contact.secondaryText')}</span>
          </div>
          <ContactForm />
        </div>
      </div>
      <Address />
    </LayoutLanding>
  );
};

export default Landing;
