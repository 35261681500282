import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import '../../styles/components/_components.map.scss';

const LandingIMG = () => {
  const data = useStaticQuery(graphql`
      query {
          map: file(relativePath: { eq: "get-out-of-timeshare.webp" }) {
              childImageSharp {
                  fluid(maxWidth: 562) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <Img
      fluid={data.map.childImageSharp.fluid}
      className="c-card"
    />
  );
};

export default LandingIMG;
