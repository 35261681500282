/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Header from './Header';
import Footer from './Footer';

// require('../styles/main.scss');

const Layout = ({ children, switchLang }) => {
  return (
    <>
      <Header siteTitle="Mexican Timeshare Solutions" noMenu />
      <main className="withLinks">
        {children}
      </main>
      <Footer switchLang={switchLang} onlyCopy />
    </>
  );
};
export default Layout;
