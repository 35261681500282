/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';

import Map from './images/Map';

const Address = () => {
  return (
    <div className="o-wrapper">
      <div className="o-layout u-margin-top u-margin-bottom">
        <div className="o-layout__item u-3/12@desktop u-push-3/12@desktop u-6/12 u-10/12@mobile u-push-1/12@mobile">
          <span className="u-h4 u-margin-bottom-small u-margin-top-small">
            Address <EnvironmentOutlined />
          </span>
          <p>8400 Normandale Lake Blvd suite 920, Bloomington, MN 55437, United States</p>
        </div>
        <div className="o-layout__item u-3/12@desktop u-push-3/12@desktop u-6/12 u-10/12@mobile u-push-1/12@mobile">
          <Map />
          <a
            href="https://www.google.com/maps/place/8400+Normandale+Lake+Blvd+%23920,+Bloomington,+MN+55437,+EE.+UU./@44.8532747,-93.3579257,17z/data=!3m1!4b1!4m5!3m4!1s0x87f6239424fe00f5:0x45d7a06b7b4651dc!8m2!3d44.8532709!4d-93.355737"
            target="_blank"
            rel="noreferrer"
            className="c-blockLink"
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
