import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import '../../styles/components/_components.map.scss';

const Map = () => {
  const data = useStaticQuery(graphql`
      query {
          map: file(relativePath: { eq: "map.webp" }) {
              childImageSharp {
                  fluid(maxWidth: 259) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <Img
      fluid={data.map.childImageSharp.fluid}
      loading="eager"
      fadeIn={false}
      className="c-map"
    />
  );
};

export default Map;
