import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { navigate } from 'gatsby';
import useSiteMetadata from '../utils/useSiteMetadata';
import Input from './Input';
import CheckBox from './CheckBox';
import Loader from './Loader';
import useI18n from '../utils/useI18n';
import '../styles/components/_components.contactForm.scss';

const ContactForm = () => {
  const { apiURL } = useSiteMetadata();
  const { t } = useI18n();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const {
    handleSubmit,
    register,
    errors: formErrors,
  } = useForm({
    defaultValues: { privacy: true },
  });

  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors]);

  const onSubmit = (requestData) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('data[Contact][name]', requestData.name);
    formData.append('data[Contact][email]', requestData.email);
    formData.append('data[Contact][phone]', requestData.phone);
    formData.append('data[Contact][phone_confirm]', requestData.phone);
    formData.append('data[Contact][message]', requestData.message);
    formData.append('data[Contact][privacy]', requestData.privacy ? '1' : '0');
    axios({
      url: `${apiURL}/api/contacts/add`,
      method: 'POST',
      data: formData,
    })
      .then((result) => result.data)
      .then((result) => {
        if (result.status === 'ok') {
          navigate(t`url.thanks`);
        }
        if (result.status === 'validationError') {
          setErrorMessage(result.message);
          const newErrors = {};
          Object.keys(result.error).forEach((field) => {
            newErrors[field] = { message: result.error[field] };
          });
          setErrors(newErrors);
        }
      })
      .catch(console.log);
  };

  return (
    <form
      name="contact"
      onSubmit={handleSubmit(onSubmit)}
      className="c-contactForm u-margin-bottom-huge"
    >
      {errorMessage !== null && <p className="c-contactForm__error">{errorMessage}</p>}
      {/*
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer />
      </Helmet>
      */}
      <div className="c-contactForm__content o-layout u-margin-top u-margin-bottom">
        <div className="c-contactForm__column u-12/12 u-6/12@tablet o-layout__item">
          <Input
            label={t('label.fullname')}
            name="name"
            placeholder={t('placeholder.fullname')}
            ref={register({
              required: {
                value: true,
                message: t('requiredField'),
              },
            })}
            error={errors.fullname && errors.fullname.message}
          />
          <Input
            label={t('label.email')}
            name="email"
            type="email"
            placeholder="example@email.com"
            ref={register({
              required: {
                value: true,
                message: t('requiredField'),
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: t('invalidEmail'),
              },
            })}
            error={errors.email && errors.email.message}
          />
          <Input
            name="phone"
            label={t('label.phone')}
            type="tel"
            placeholder={t('placeholder.phone')}
            ref={register}
            error={errors.phone && errors.phone.message}
          />
        </div>
        <div className="c-contactForm__column u-12/12 u-6/12@tablet o-layout__item">
          <Input
            ref={register({
              required: {
                value: true,
                message: t('requiredField'),
              },
            })}
            label={t('label.message')}
            name="message"
            placeholder={t('placeholder.message')}
            type="textarea"
            error={errors.comments && errors.comments.message}
          />
        </div>
      </div>
      <div className="u-12/12 o-layout__item c-contactForm__checkbox">
        <CheckBox
          ref={register({
            required: {
              value: true,
              message: t('mustAcceptPrivacy'),
            },
          })}
          name="privacy"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t('acceptPrivacy', {
                privacyUrl: t('url.privacy'),
              }),
            }}
          />
        </CheckBox>
      </div>
      {/*
      <div className="c-contactForm__captcha">
        <ReCaptcha
          sitekey="6LdB1y8UAAAAAOPUA93X2CWY7Sr79l9ayU6uJ0G_"
          render="onload"
          verifyCallback={() => {
            console.log('verificado');
          }}
          onloadCallback={console.log}
        />
      </div>
      */}
      <button type="submit" className="c-button c-button--huge u-margin-top">
        {t('sendMessage')}
      </button>
      {loading && <Loader full />}
    </form>
  );
};

export default ContactForm;
